@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  font-family: "Roboto", sans-serif;
}

//login





$tablet: 768px;
$laptop: 992px;
$desktop: 1200px;



/* Custom CSS for Itinerary Section */
.itinerary-section {
  background-color: #f8f8f8;
  background-image: url('./assets/bg-9.png');
}

.arrow-box {
  background-color: #fff;
  border: 2px solid #ccc;
  padding: 8px;
  border-radius: 50%;
}

.arrow {
  font-size: 20px;
  line-height: 1;
}

.city-description {
  color: #555;
  animation: fade-in 1s ease-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}